export default {
    env: "#{envName}",
    tenant: "#{tenant}",
    clientId: "#{clientId}",
    audience: "#{audience}",
    apiEndPoint: "#{apiEndPoint}",
    fmServicesUrl: "#{fmServicesUrl}",
    fmServiceAudience: "#{fmServiceAudience}",
    fmImportServicesUrl: "#{fmImportServicesUrl}",
    licenseServicesUrl: "#{licenseServicesUrl}",
    cmsUrl : "#{fmImportServicesUrl}",
    amsUrl : "#{amsUrl}"
}