import React, { useState, useEffect } from "react"
import ReactTable from 'react-table'
import Layout from "../layout";
import { API_URLS, API_ENDPOINT } from "./../../constants"
import { getFmToken, getToken } from "../../adalConfig"
import { Icon, DateRangePicker, Dropdown, ButtonToolbar, Button, IconButton } from 'rsuite';
import SearchBar from "../SearchBar";
import './main.css';
import Notification from "../../shared/components/notification/notification";
import moment from 'moment';
import AddWorkstationModal from "./addWorkstation";
import UpdateFactoryModal from "./updateFactory";



function Dashboard(props) {
    const [data, setData] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [dataLoading, setLoading] = useState(true)
    const [isModalOpen, setModalOpen] = useState(false)
    const [addWSLoading, setAddWSLoading] = useState(false)
    const [messageType, setmessageType] = useState("");
    const [message, setmessage] = useState("");
    const [productAndVersions, setProductAndVersions] = useState({ 'products': [], 'versions': [], skuData: [] });
    const [getProductVersionLoading, setProductVersionLoading] = useState(false)
    const [getUserFactoryDataLoading, setUserFactoryDataLoading] = useState(false)
    const [userFactoryData, setUserFactoryData] = useState({ 'workstationFactory': [], 'selectedFactory': [] });
    const [factoryModalState, setfactoryModalState] = useState(false);
    const [factoryUpdateLoading, setUpdateFactoryLoading] = useState(false);


    const columns = [{
        Header: 'Registration Date',
        accessor: 'RegistrationDate',
        filterable: true,
        maxWidth: 180
    }, {
        Header: 'Serial Number',
        accessor: 'SerialNumber',
        filterable: true
    },
    {
        Header: 'Sold To',
        accessor: 'SoldTo',
        filterable: true
    },
    {
        Header: 'SKU',
        accessor: 'Sku',
        filterable: true,
        Cell: (props) => {
            return <p>{props.original.Material ? props.original.Material : props.original.Sku}</p>
        }
    },
    {
        Header: 'Version',
        accessor: 'Version',
        filterable: true
    },
    {
        Header: 'Sold To Account Number',
        accessor: 'SoldToAccountNumber',
        filterable: true
    },
    {
        Header: 'SAP Order No.',
        accessor: 'SapOrderNo',
        filterable: true
    },
    {
        Header: 'Remarks',
        accessor: 'Remarks',
        filterable: true
    }
    ];
    useEffect(() => {
        getUserFactoryData();
    }, [])


    let getWorkstations = async () => {
        setData([])
        setFilteredData([])
        let fmToken = await getToken();
        setLoading(true)
        fetch(`${API_ENDPOINT}${API_URLS.getAllFactoryWorkstations()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${fmToken}`
            }
        }).then(data =>
            data.json()
        ).then(
            (res) => {
                const val = formatWorstationData(res && res.workstations ? res.workstations : []);
                setData(val)
                setFilteredData(val)
                setLoading(false)
            }
        ).catch((err) => {
            setLoading(false)
        })
    }

    let getUserFactoryData = async () => {
        let fmToken = await getToken();
        setUserFactoryDataLoading(true)
        fetch(`${API_ENDPOINT}${API_URLS.getUserFactoryData()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${fmToken}`
            }
        }).then(data =>
            data.json()
        ).then(
            (res) => {
                setUserFactoryData(res);
                setUserFactoryDataLoading(false)
                if (res.selectedFactory.length) {
                    getWorkstations();
                    getWorkstationProductsVersions(res.selectedFactory[0].FactoryId);
                    const factoryObj = res.workstationFactory.filter(f => f.Id === res.selectedFactory[0].FactoryId);
                    setUserFactoryData({workstationFactory: res.workstationFactory , selectedFactory : factoryObj});
                } else {
                    setfactoryModalState(true);
                }
            }
        ).catch((err) => {
            setUserFactoryDataLoading(false)
        })
    }

    const LincenseNameMapperWS = {
        'VO': 'View',
        'CTRL': 'Control',
        'SCS': 'Smoke Control',
        'VP': 'Paging'
    };


    let formatWorstationData = (data) => {
        return JSON.parse(JSON.stringify(data)).map(element => ({
            ...element, ...JSON.parse(element.AdditionalProperties ? element.AdditionalProperties : JSON.stringify({})),
            ... { 'RegistrationDate': element.RegistrationDate ? moment(element.RegistrationDate).format("DD MMM YYYY") : 'Not Available' }
        }));
    }

    let getLicenseString = (license) => {
        if (!license) {
            return 'None';
        } else {
            const finalString = [];
            const res = license.split('_');
            res.forEach(element => {
                if (LincenseNameMapperWS[element]) {
                    finalString.push(LincenseNameMapperWS[element]);
                }
            });
            return finalString.join(' + ');
        }
    }

    let formatProductVersions = (data) => {
        data.skuData.forEach(element => {
            element['displayName'] = element.Material ? element.Material : element.SKU;
        });
        return {'versions': data.versions.filter(p => p.IsLatest), 'skuData': data.skuData };
    }
    
    let removeNotSupportedFactorySKU = (data, factoryId)=>{
        if(factoryId===1)//Northford factory- US region
        {
            let skuToRemove = ['OW_VO','OW_VO_CTRL','FP_VO','FP_VO_CTRL','FSM_VO'];
            data.skuData = data.skuData.filter(
                item => (skuToRemove.indexOf(item.SKU) === -1)   
            );
        }
    }

    let getWorkstationProductsVersions = async (factoryId) => {
        let fmToken = await getToken();
        setProductVersionLoading(true);
        fetch(`${API_ENDPOINT}${API_URLS.getWorkstationProductsVersions()}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${fmToken}`
            }
        }).then(data =>
            data.json()
        ).then(
            (res) => {
                setProductVersionLoading(false);
                removeNotSupportedFactorySKU(res.workstations,factoryId);
                setProductAndVersions(formatProductVersions(res.workstations));
            }
        ).catch((err) => {
            setProductVersionLoading(false);
        })
    }

    let filterMethod = (filter, row, column) => {
        const id = filter.pivotId || filter.id
        return row[id] !== undefined ? String(row[id].toLowerCase()).includes(filter.value.toLowerCase()) : true
    }

    let addWorkstation = async (data) => {
        let fmToken = await getToken();
        const sku = productAndVersions.skuData.filter(s => s.Id.toString() === data.SKU.toString() )[0];
        data.VersionId = parseInt(data.VersionId, 10);
        data.BrandId = parseInt(sku.BrandId, 10);
        data.FeatureId = sku.FeatureId;
        data.SKU = sku.SKU;
        data['Id'] = sku.Id;
        data['FactoryId'] = userFactoryData.selectedFactory[0].Id;
        data['AdditionalProperties'] = JSON.stringify({ 'SapOrderNo': data.SapOrderNo, 'Remarks': data.Remark, 'SoldToAccountNumber': data.SoldToAccountNumber });
        setAddWSLoading(true)
        fetch(`${API_ENDPOINT}${API_URLS.addFactoryWorkstation()}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${fmToken}`
            },
            body: JSON.stringify(data)
        }).then(data =>
            data.json()
        ).then(
            (res) => {
                if (res.success) {
                    setmessage(res.message);
                    setmessageType('success');
                } else {
                    setmessage(res.message);
                    setmessageType('error');
                }
                setTimeout(() => {
                    setmessage('');
                    setmessageType('');
                }, 7000)
                setAddWSLoading(false);
                setModalOpen(false);
                getWorkstations();
            }
        ).catch((err) => {
            console.log(err);
            setmessage('Adding Workstation Failed. Please try adding Workstation again later.');
            setmessageType('error');
            setTimeout(() => {
                setmessage('');
                setmessageType('');
            }, 7000)
            setAddWSLoading(false);
            setModalOpen(false);
        })
    }


    let toggleModalState = () => {
        setModalOpen(!isModalOpen);
    }

    let toggleFactoryModalState = () => {
        setfactoryModalState(!factoryModalState);
    }

    let updateFactory = async (data) => {
        let fmToken = await getToken();
        setUpdateFactoryLoading(true)
        fetch(`${API_ENDPOINT}${API_URLS.updateFactory(data.FactoryId)}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                'Authorization': `Bearer ${fmToken}`
            }
        }).then(data =>
            data.json()
        ).then(
            (res) => {
                setUpdateFactoryLoading(false);
                if (res.success) {
                    setmessage('Factory updated successfully.');
                    setmessageType('success');
                    setfactoryModalState(false);
                    getUserFactoryData();
                } else {
                    setmessage('Error occured.Please try again.');
                    setmessageType('error');
                }
                setTimeout(() => {
                    setmessage('');
                    setmessageType('');
                }, 7000)
            }
        ).catch((err) => {
            console.log(err);
            setmessage('Error Occured. Please try again later.');
            setmessageType('error');
            setTimeout(() => {
                setmessage('');
                setmessageType('');
            }, 7000)
            // setAddWSLoading(false);
            // setModalOpen(false);
        })
    }

    return (
        <Layout>
            <div className="content">
                <Notification messageType={messageType} message={message} />
                <div className="pb-10 flex flex-row justify-between align-center">
                    <div className="flex-col">
                        <h3>Workstations</h3>
                        <div className="subheaderText">View and manage workstations</div>
                    </div>
                    <div className="flex-row">
                        <IconButton disabled={getProductVersionLoading || getUserFactoryDataLoading}  onClick={toggleFactoryModalState} icon={<Icon icon="edit" />} placement="left">
                             {userFactoryData.selectedFactory.length ? 'Factory : ' + userFactoryData.selectedFactory[0].FactoryName : 'Loading.'}
                        </IconButton>
                        <Button disabled={getProductVersionLoading || getUserFactoryDataLoading} onClick={toggleModalState} className="flat-button upload-button h-100">{getProductVersionLoading || getUserFactoryDataLoading ? 'Loading' : 'Add Workstation'}</Button>
                    </div>
                </div>
                {isModalOpen && <AddWorkstationModal loading={addWSLoading} modalOpen={isModalOpen} closeModal={toggleModalState} productAndVersions={productAndVersions} submitAddWSCallBack={addWorkstation} />}
                {factoryModalState && <UpdateFactoryModal loading={factoryUpdateLoading} modalOpen={factoryModalState} closeModal={toggleFactoryModalState} FactoryData={userFactoryData} submitAddWSCallBack={updateFactory} />}

                <ReactTable loading={dataLoading || getUserFactoryDataLoading}
                    loadingText='Loading...'
                    noDataText='No Workstations Availeble'
                    defaultFilterMethod={filterMethod}
                    showPagination={true} className="-highlight" data={filteredData} columns={columns} />
            </div>
        </Layout>)
}

export default Dashboard