import React from 'react'
import { Modal } from 'semantic-ui-react'
import { Form as ReactFinalForm, Field } from 'react-final-form';
import { required } from '../../validators';
import "./main.css";
import { OnChange } from 'react-final-form-listeners'

class UpdateFactoryModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            factoryData: this.props.FactoryData
        };
    }

    componentDidMount() {
    }

    render() {
        // const { subLicenseTypes } = this.state;
        return <Modal   closeOnEscape={false}
        closeOnDimmerClick={false} size={'small'} open={this.props.modalOpen} onClose={this.props.closeModal}>
            <Modal.Header>Choose your Factory</Modal.Header>

            <ReactFinalForm
                onSubmit={this.props.submitAddWSCallBack}
                render={({ handleSubmit, pristine, invalid, values }) => {
                    return <form onSubmit={handleSubmit}>
                        <Modal.Content>
                            <fieldset className="" disabled={this.props.loading}>
                                <div className="form-content">
                                    <div className='two-fields-row-class pl-10 pr-10 pb-10'>
                                        <div className="input-field-header pb-15 pt-20">Please choose your workstation factory *</div>
                                        <Field name="FactoryId"
                                            validate={required}
                                            component="select"
                                            disabled={this.props.loading}
                                            initialValue={this.state.factoryData.selectedFactory.length ? this.state.factoryData.selectedFactory[0].Id : this.state.factoryData.workstationFactory[0].Id}
                                        >
                                            {this.state.factoryData.workstationFactory.map(function (factory, index) {
                                                return <option key={index} value={factory.Id}> {factory.FactoryName}</option>
                                            })}
                                        </Field>
                                    </div>
                                
                                </div>
                            </fieldset>
                        </Modal.Content>
                        <Modal.Actions>
                            <div className="pt-20 pb-20 pr-15 modal-footer flex flex-row flex-row-r">
                                <button className="btn btn-primary m-5" primary type="submit" onClick={handleSubmit} disabled={invalid || this.props.loading}>
                                    {this.props.loading ? "Updating..." : "Update Factory"}
                                </button>
                            </div>
                        </Modal.Actions>
                    </form>
                }} >
            </ReactFinalForm>
        </Modal >
    }
}

export default UpdateFactoryModal