import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";
import Dashboard from './container/dashboard';
import 'rsuite/lib/styles/index.less';
import 'rsuite/dist/styles/rsuite-default.css' 
import Certificate from './container/certificateManager';


function AppRouter() {
  return (
    <Router>
      <Route path="/" exact component={Dashboard}/>
      <Route path="/certificate" exact component={Certificate}/>
    </Router>
  );
}

export default AppRouter;
