import React, { useState } from 'react';
import Layout from '../layout';
import { AMS_ENDPOINT, API_URLS} from "../../constants";
import { getFmToken } from '../../adalConfig';

//called during file upload
function Certificate() {
    const [fileName, setFileName] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [message, setmessage] = useState("");
    const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleFileUpload = (event) => {
        setErrorMessage("");
        setmessage("");
        const file = event.target.files[0];
        if (file) {
            if (file.name.endsWith('.csr')) {
                setFileName(file.name);
                setErrorMessage(""); 
                setFile(file);
            } else {
                setFileName("");
                setmessage("");
                setErrorMessage("Invalid file type. Please upload a .csr file.");
            }
        } else {
            setFileName("");
            setmessage("");
            setErrorMessage("Please select a file to upload.");
        }
    };

// Create an instance of AbortController to cancel the API calls
const abortController = new AbortController();

function base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}

//call accessmanagement API and generate the certificate.
const handleGenerateCertificate = async () => {
    setIsLoading(true);
    const data = new FormData();
    data.append('csrFile', file);
    let fmToken = await getFmToken();

    let accessManagementAPI = AMS_ENDPOINT;
    let endpoint = API_URLS.generateCertificate();
    
    const requestUrl = accessManagementAPI + endpoint;

    fetch(requestUrl, {
        method: 'POST',
        headers: {
            'Authorization': `Bearer ${fmToken}`
        },
        body: data,
        signal: abortController.signal
    })
    .then(response => response.json()) // Convert the response to JSON
    .then(json => { 
        setIsLoading(false);  
        if (json.responseCode !== 0 && json.responseCode === 4) {
            setErrorMessage("Invalid CSR file.");
            setmessage("");
            return; 
        }
        else if (json.responseCode !== 0) {
            setErrorMessage("Something went wrong.");
            setmessage("");
            return; 
        }
        const certContents = json.certContents;
        const decodedCert = base64ToArrayBuffer(certContents);
        const blob = new Blob([decodedCert], {type: 'application/x-x509-ca-cert'});
       
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = json.certFilePath + '.crt';
        a.click();
        setFileName("");
        setmessage("Certificate is generated and downloaded successfully.");
    })
    .catch((error) => {
        setIsLoading(false);
        setmessage("");
        // Ignore the error if the fetch was aborted
        if (error.name === 'AbortError') return;
        console.error('Error:', error);
    });
};

//only called when user cancels the operation
const handleCancelOperation = () => {
    handleRemoveFile();
    abortController.abort();
};

//called when user removes the file
    const handleRemoveFile = () => {
         // Reset the file input
    document.querySelector('input[type="file"]').value = '';
        setFile(null);
        setFileName("");
        setErrorMessage("");
        setmessage("");
    };

    return (
        <Layout>
            <div className="content">
                <div className="pb-10 flex flex-row justify-between align-center">
                    <div className="flex-col">
                        <h3>Certificate Manager</h3>
                        <div className="subheaderText">Upload the certificate request that you have generated from the certificate manager & generate the certificate for CLSS FirstVision.</div>
                        <br />
                        <input type="file" onChange={handleFileUpload} accept=".csr"/>
                        <br />
                        <br />
                        {errorMessage && <div className="upload-error">{errorMessage}</div>}
                        {message && <div className="upload-success">{message}</div>}
                        {isLoading && <div>Downloading...</div>}
                    </div>
                </div>
                <br />
                <div className="flex-col">
                    <button className="btn btn-primary m-5" style={{ marginRight: '10px' }} onClick={handleGenerateCertificate} disabled={!file}>
                        Generate Certificate
                        </button>
                    <button className="btn btn-secondary m-5" onClick={handleCancelOperation}>Cancel</button>
                </div>
            </div>
        </Layout>
    );
}

export default Certificate;
