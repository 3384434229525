import { environmentVariables } from "./env";

export const API_URLS = {
    getAllFactoryWorkstations: () => "/api/workstation/getAllFactoryWorkstations",
    addFactoryWorkstation: () => `/api/workstation/addFactoryWorkstation`,
    getWorkstationProductsVersions: () => `/api/workstation/getWorkstationProductsVersions`,
    getUserFactoryData: () => `/api/workstation/getFactoryData`,
    updateFactory: (factoryId) => `/api/workstation/updateUserFactory/${factoryId}`,
    generateCertificate: () => `/api/Certificates/GenerateCertificateFromCSR`,
}

export const API_ENDPOINT = environmentVariables.apiEndPoint;
export const FM_ENDPOINT = environmentVariables.fmServicesUrl;
export const FM_IMPORT_ENDPOINT = environmentVariables.fmImportServicesUrl;
export const LICENSE_ENDPOINT = environmentVariables.licenseServicesUrl;
export const CMS_ENDPOINT = environmentVariables.cmsUrl;
export const AMS_ENDPOINT = environmentVariables.amsUrl;