import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';
import { environmentVariables } from './env';

console.log(environmentVariables)
export const adalConfig = {
  tenant: environmentVariables.tenant,
  clientId: environmentVariables.clientId,
  endpoints: {
    api: environmentVariables.audience,
  },
  postLogoutRedirectUri: window.location.origin,
  redirectUri: window.location.origin,
  cacheLocation: 'sessionStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);

export const getToken = () => {
  return authContext.getCachedToken(authContext.config.clientId);
};

export const getFmToken = () => {
  return new Promise((resolve, reject) => {
    authContext.acquireToken(environmentVariables.fmServiceAudience, (errDesc, token) => {
      if (errDesc) {
          resolve(false);
      } else {
        resolve(token);
      }
    });
  })
}