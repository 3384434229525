import React, { Component } from 'react';
import './main.css';
import { Sidenav, Nav, Icon } from 'rsuite';
import 'rsuite/lib/styles/index.less';
import { getSideNavSelection } from '../../utils';
import { Link } from 'react-router-dom';

class SideBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expanded: false,
            activeKey: getSideNavSelection(window.location.href)
        };

        this.handleToggle = this.handleToggle.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
    }
    handleToggle() {
        this.setState({
            expanded: !this.state.expanded
        });
        this.props.parentCallback(this.state);
    }
    handleSelect(eventKey) {
        this.setState({
            activeKey: eventKey
        });
    }
    render() {
        const { expanded } = this.state;

        return (
            <div className="sidenav black-bg white-text">
                <div >
                    <Icon size="2x" className="bar-icon" icon="bars" onClick={this.handleToggle} />
                </div>
                <Sidenav
                    expanded={expanded}
                    defaultOpenKeys={['3', '4']}
                    activeKey={this.state.activeKey}
                    onSelect={this.handleSelect}
                >
                    <Sidenav.Body>
                    <Nav>
                    <Link to="/">
                        <Nav.Item eventKey="dashboard" icon={<Icon size="2x" className="white-text" icon="dashboard" />}>
                            <span className="white-text">Dashboard</span>
                        </Nav.Item>
                    </Link>
                    </Nav>
                    <Nav>
                    <Link to="/certificate">
                        <Nav.Item eventKey="certificate" icon={<Icon size="2x" className="white-text" icon="views-authorize" />}>
                            <span className="white-text">Certificate Manager</span>
                    </Nav.Item>
                    </Link>
                    </Nav>                  
                    </Sidenav.Body>
                </Sidenav>
            </div>
        );
    }
}

export default SideBar;
