export const required = value => (value ? undefined : 'Required')
export const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
export const mustBeNonZero = value => (value === 0 ? 'Cannot be zero' : undefined)
export const mustContainHash = value => (!value.includes('#') ? 'Invalid Workstaion ID#' : undefined)
export const noWhiteSpaceAllowed = value => (((value.toString()).trim().length === 0) ? 'Invalid' : undefined)
export const mustBeInteger = value => (value % 1 ? 'Decimals not allowed' : undefined)
export const minValue = min => value =>
  isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

