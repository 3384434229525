import React from 'react'
import { Modal } from 'semantic-ui-react'
import { Form as ReactFinalForm, Field } from 'react-final-form';
import { required, composeValidators, mustContainHash, noWhiteSpaceAllowed } from '../../validators';
import "./main.css";
import { OnChange } from 'react-final-form-listeners'

class AddWorkstationModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            skuDataValues : this.props.productAndVersions.skuData
        };
    }

    componentDidMount() {
    }

    render() {
        // const { subLicenseTypes } = this.state;
        return <Modal size={'small'} open={this.props.modalOpen} onClose={this.props.closeModal}>
            <Modal.Header>Add Workstation</Modal.Header>

            <ReactFinalForm
                onSubmit={this.props.submitAddWSCallBack}
                render={({ handleSubmit, pristine, invalid, values }) => {
                    return <form onSubmit={handleSubmit}>
                        <Modal.Content>
                            <fieldset className="" disabled={this.props.loading}>
                                <div className="form-content">
                                    {/* serialNumber */}
                                    <Field name="SerialNumber"
                                        validate={composeValidators(required, mustContainHash)}
                                    >
                                        {({ input, meta }) => (
                                            <div className='field-container two-fields-row-class pl-10 pr-10'>
                                                <div className="input-field-header pb-5">Workstation ID # *</div>
                                                <input {...input} type="text" placeholder="" />
                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    {/* Sold To */}
                                    <Field name="SoldTo"
                                        validate={composeValidators(required,noWhiteSpaceAllowed)}
                                    >
                                        {({ input, meta }) => (
                                            <div className='field-container two-fields-row-class pl-10 pr-10'>
                                                <div className="input-field-header pb-5">Sold To *</div>
                                                <input {...input} type="text" placeholder="" />
                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>

                                    <Field name="SoldToAccountNumber"
                                        validate={composeValidators(required, noWhiteSpaceAllowed)}
                                    >
                                        {({ input, meta }) => (
                                            <div className='field-container two-fields-row-class pl-10 pr-10'>
                                                <div className="input-field-header pb-5">Sold To Account Number *</div>
                                                <input {...input} type="text" placeholder="" />
                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>


                                    <Field name="SapOrderNo"
                                        validate={composeValidators(required, noWhiteSpaceAllowed)}
                                    >
                                        {({ input, meta }) => (
                                            <div className='field-container two-fields-row-class pl-10 pr-10'>
                                                <div className="input-field-header pb-5">SAP Order Number *</div>
                                                <input {...input} type="text" placeholder="" />
                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>


                                    

                   
                                    <div className='field-container two-fields-row-class pl-10 pr-10'>
                                        <div className="input-field-header pb-5">SKU *</div>
                                        <Field name="SKU"
                                            validate={required}
                                            component="select"
                                            disabled={this.props.loading}
                                            initialValue={this.state.skuDataValues[0].Id}
                                        >
                                            {this.state.skuDataValues.map(function (sku, index) {
                                                return <option key={index} value={sku.Id}> {sku.displayName}</option>
                                            })}
                                        </Field>
                                    </div>

                                    <div className='field-container two-fields-row-class pl-10 pr-10'>
                                        <div className="input-field-header pb-5">Version *</div>
                                        <Field name="VersionId"
                                            validate={required}
                                            disabled={this.props.loading}
                                            component="select"
                                            initialValue={this.props.productAndVersions.versions[0].Id}
                                        >
                                            {this.props.productAndVersions.versions.map(function (version, index) {
                                                return <option key={index} value={version.Id}> {version.Version}</option>
                                            })}
                                        </Field>
                                    </div>

                                    <Field name="Remark"
                                        validate={composeValidators(required, noWhiteSpaceAllowed)}
                                    >
                                        {({ input, meta }) => (
                                            <div className='field-container two-fields-row-class pl-10 pr-10 pb-20'>
                                                <div className="input-field-header pb-5">Remarks *</div>
                                                <input {...input} type="text" placeholder="" />
                                                {meta.error && meta.touched && <span>{meta.error}</span>}
                                            </div>
                                        )}
                                    </Field>
                                </div>
                            </fieldset>
                        </Modal.Content>
                        <Modal.Actions>
                            <div className="pt-20 pb-20 pr-15 modal-footer flex flex-row flex-row-r">
                                <button className="btn btn-primary m-5" primary type="submit" onClick={handleSubmit} disabled={pristine || invalid || this.props.loading}>
                                    {this.props.loading ? "Adding..." : "Add Workstation"}
                                </button>
                                <button className="btn btn-secondary m-5" onClick={this.props.closeModal} negative>Close</button>
                            </div>
                        </Modal.Actions>
                    </form>
                }} >
            </ReactFinalForm>
        </Modal >
    }
}

export default AddWorkstationModal