export default {
    tenant: "devbhonidentity.onmicrosoft.com",
    clientId: "2d14d437-bcf1-4341-bad4-14ee6dc19e57",
    audience: "https://devbhonidentity.onmicrosoft.com/2d14d437-bcf1-4341-bad4-14ee6dc19e57",
    apiEndPoint: "http://localhost:3000/node/node-server/myapp",
    fmServicesUrl: "https://fire.dev.honeywell.com/fmservices",
    fmServiceAudience: "https://devbhonidentity.onmicrosoft.com/53eef939-6715-4e70-918a-44ab619ee723",
    fmImportServicesUrl: "https://fire.dev.honeywell.com/glsscms",
    licenseServicesUrl: "https://fire.dev.honeywell.com/licensingservice" ,
    cmsUrl : "https://fire.dev.honeywell.com/glsscms",
    amsUrl: "https://fire.qa.us.honeywell.com/accessmanagement"
}

// qa config
// export default {
//     tenant: "honidentitydev.onmicrosoft.com",
//     clientId: "6a15f8c1-d279-4b7f-ac05-ce7927894e51",
//     audience: "https://honidentitydev.onmicrosoft.com/6a15f8c1-d279-4b7f-ac05-ce7927894e51",
//     apiEndPoint: "http://localhost:3000/node/node-server/myapp",
//     fmServicesUrl: "https://fire.qa.eu.honeywell.com/fmservices",
//     fmServiceAudience: "https://honidentitydev.onmicrosoft.com/def9341b-3773-474a-81a2-3bccbcab37a5",
//     fmImportServicesUrl: "https://fire.qa.eu.honeywell.com/glsscms",
//     licenseServicesUrl: "https://fire.dev.honeywell.com/licensingservice" ,
//     amsUrl: "https://fire.qa.us.honeywell.com/accessmanagement"
// }