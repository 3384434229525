export const getSideNavSelection = (route) => {
    const routeValues = route.split(" ");
    if (routeValues.findIndex((val) => val.includes('settings')) > -1) {
        return 'settings'
    }
    else if (routeValues.findIndex((val) => val.includes('certificate')) > -1) {
        return 'certificate'
    } else {
        return 'dashboard'
    }
}