import React, { Component } from 'react';
import { authContext } from '../../adalConfig';
import './main.css';
import { Link } from 'react-router-dom';
import SideBar from '../sidebar';

export default class Layout extends Component {

    state = { 'expanded': true }

    logOut = () => {
        authContext.logOut();
    }

    handleSelect = (state) => {
        this.setState({ expanded: state.expanded });
    }
    render() {
        return <div className="h-100 app-content flex flex-row">
            <SideBar parentCallback={this.handleSelect}></SideBar>
            <div className="right-content">
                <div className="black-bg header-content flex white-text">
                    <span className="flex-grow">
                        <b>Honeywell | </b>
                        CLSS Workstation Factory Tool
                        </span>
                    <span className="cursor font-sm mr-50" onClick={this.logOut}>Log Out</span>
                </div>
                {/* <div className="navigation-bar m-10">
                    <span className="cursor font-sm ml-10 section-link"><Link to={`/`}>All Esds</Link></span>
                    <span className="cursor font-sm ml-10 section-link"><Link to={`/settings`}>Settings</Link></span>

                </div> */}
                <main style={{ marginLeft: this.state.expanded ? '52px' : '140px' }} className="h-100 layout-content">
                    {this.props.children}
                </main>
            </div>
        </div>
    }
}