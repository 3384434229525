import React, { Component } from 'react';
import './main.css';
import 'rsuite/lib/styles/index.less';
import { Input, InputGroup, Icon } from 'rsuite';

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: ''
        };

        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
    }
    handleSearchSubmit() {
        this.props.parentCallback(this.state);
    }

    clearSearch() {
        this.setState({
            searchText: ''
        })
    }

    handleSearch(event) {
        this.setState({
            searchText: event
        }, function () {
            this.props.parentCallback(this.state);
        });
    }
    render() {
        return (
            <InputGroup size="lg">
                <Input value={this.state.searchText} onKeyPress={event => {
                    if (event.key === 'Enter') {
                        this.handleSearchSubmit()
                    }
                }} onChange={this.handleSearch.bind(this)} placeholder="Search Anything..." />
                <InputGroup.Button><Icon icon="search" onClick={this.handleSearchSubmit} /></InputGroup.Button>
            </InputGroup>
        );
    }
}

export default SearchBar;
